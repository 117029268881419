.root {
  padding-top: clamp(95px, calc(10.9375rem + ((1vw - 19.2px) * 12.5)), 175px);
  padding-bottom: clamp(
    30px,
    calc(1.875rem + ((1vw - 19.2px) * -14.8437)),
    125px
  );
}

.performance {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  text-transform: uppercase;
  z-index: 1;
  position: relative;
  margin-bottom: clamp(50px, calc(3.75rem + ((1vw - 19.2px) * 1.5625)), 60px);
}

.philosophy {
  font-size: 12px;
  color: var(--grey2);
  line-height: clamp(15.6px, calc(1.1875rem + ((1vw - 19.2px) * 0.5313)), 19px);
  font-weight: 400;
  margin-top: 5px;
  text-transform: uppercase;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: clamp(80px, calc(5.625rem + ((1vw - 19.2px) * 1.5625)), 90px);
  line-height: clamp(68px, calc(4.5rem + ((1vw - 19.2px) * 0.625)), 72px);
  margin-left: 9px;

  text-transform: uppercase;
  color: var(--night);
  em {
    display: block;
    color: var(--brown2);
    font-style: normal;
  }
}

.free {
  color: var(--grey3);
}

.overlap {
  color: var(--brown);
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 450px;
}

.wrapper:before {
  content: "";
  background-image: url("../../../assets/img/mainAboutSection/1920about.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  height: 100%;
  width: var(--pseudo-element-width);
  position: absolute;
  top: 0;
  left: 0;
}

.aboutFrame {
  display: none;
}

.right {
  display: grid;
  grid-template-rows: 5fr 1fr;
  gap: 10px;
  margin-left: auto;
  max-width: clamp(318px, calc(29.75rem + ((1vw - 19.2px) * 24.6875)), 476px);
  width: 100%;
  min-height: inherit;
  height: 100%;
}

.content {
  height: clamp(340px, calc(28.125rem + ((1vw - 19.2px) * 17.1875)), 450px);
}

.down {
  display: none;
}

@media (max-width: 1280px) {
  .philosophy {
    font-weight: 300;
    font-size: clamp(9px, calc(0.75rem + ((1vw - 12.8px) * 0.3261)), 12px);
    line-height: clamp(
      11.7px,
      calc(0.975rem + ((1vw - 19.2px) * 0.6094)),
      15.6px
    );
  }

  .wrapper:before {
    background-image: url("../../../assets/img/mainAboutSection/1280about.jpg");
  }

  .title {
    font-size: clamp(36px, calc(5rem + ((1vw - 12.8px) * 4.7826)), 80px);
    line-height: clamp(28.8px, calc(4.25rem + ((1vw - 12.8px) * 4.2609)), 68px);
  }
}

@media (max-width: 1024px) {
  .root {
    padding-top: clamp(
      56px,
      calc(4.0625rem + ((1vw - 10.24px) * 1.3554)),
      65px
    );
    padding-bottom: clamp(
      49px,
      calc(12.5rem + ((1vw - 10.24px) * 22.741)),
      200px
    );
  }

  .performance {
    grid-template-columns: 1fr 2fr;
    gap: 0;
    margin-bottom: 21px;
  }
  .title {
    display: inline;
    text-align: right;
    margin-left: clamp(
      52px,
      calc(9.375rem + ((1vw - 10.24px) * 14.759)),
      150px
    );
    transform: translateY(4px);
  }

  .philosophy {
    max-width: 90px;
    width: 100%;
  }

  .aboutFrame {
    display: flex;
    max-width: var(--interval-container);
    height: clamp(224px, calc(20.4375rem + ((1vw - 10.24px) * 15.512)), 327px);
    width: 100%;
    object-position: center;
    object-fit: cover;
    margin-bottom: 20px;
  }

  .content {
    display: none;
  }

  .down {
    display: flex;
    flex-direction: column;
    max-width: 90%;
    margin: 0 auto;
  }
}

@media (min-width: 1920px) {
  .content,
  .wrapper {
    height: calc(
      23.44vw + clamp(0px, calc(0rem + ((1vw - 10.24px) * 4.8465)), 120px)
    );
  }
}
