.nav {
  display: grid;

  .item {
    text-decoration: none;
    margin-right: 60px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .item:hover {
    color: var(--brown2);
  }
}

.menu {
  :global {
    a.active {
      color: var(--brown2);
    }
  }
}

.item {
  color: var(--grey2, #565656);
  transition: 0.4s;
}

.item:hover {
  color: var(--brown, #ca8f65);
}

.menu {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  color: var(--light);
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  white-space: nowrap;
  margin-left: 4px;
  align-self: center;
}

@media (max-width: 1280px) {
  .menu {
    margin-right: clamp(0px, calc(0rem + ((1vw - 12.8px) * -2.0833)), 10px);
  }
}

@media (max-width: 850px) {
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    gap: 18.5px;
  }
}
