.root {
  flex: 0 1 auto;
  padding: 51px 0 clamp(32px, calc(2rem + ((1vw - 19.2px) * -1.7187)), 43px) 0;

  padding-bottom: 49px;
  color: var(--light);
}

.bgDark {
  background-color: var(--dark);
}

.bgLate {
  background-color: var(--late);
}

.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: clamp(9px, calc(3.9375rem + ((1vw - 19.2px) * 8.4375)), 63px);
  align-items: center;
}
.wrapper:before {
  content: "";
  position: absolute;
  top: -20px;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--dark);
}

.right {
  display: flex;
  gap: 86px;
  margin-left: auto;
}

.logo {
  justify-self: center;
}

.burger {
  display: none;
  margin-left: auto;
  border: none;
  background-color: transparent;
}

@media (max-width: 1280px) {
  .root {
    padding-bottom: clamp(
      4px,
      calc(2.6875rem + ((1vw - 12.8px) * 4.2391)),
      43px
    );
  }
}

@media (max-width: 850px) {
  .root {
    padding-top: clamp(30px, calc(3.1875rem + ((1vw - 12.8px) * 2.2826)), 51px);
  }

  .wrapper {
    display: flex;
    flex-direction: row;
  }

  .wrapper:before {
    display: none;
  }
  .burger {
    display: block;
    width: 36px;
    height: 36px;
  }

  .burger img {
    stroke: none;
  }

  .navBlock {
    display: none;
  }
  .socialBlock {
    display: none;
  }
}
