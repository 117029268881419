.root {
  padding-top: clamp(16px, calc(4.75rem + ((1vw - 19.2px) * 9.375)), 76px);
  padding-bottom: clamp(49px, calc(6.125rem + ((1vw - 19.2px) * 7.6563)), 98px);
  color: var(--grey2, #565656);
  line-height: 25px;
  h1,
  h2 {
    color: var(--black, #010005);
  }
}
.performance1,
.performance2,
.performance3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.performance1 {
  margin-bottom: clamp(31px, calc(3.75rem + ((1vw - 19.2px) * 4.5313)), 60px);
}

.performance3 {
  margin-bottom: clamp(
    123px,
    calc(12.8125rem + ((1vw - 19.2px) * 12.8125)),
    205px
  );
}

.title1 {
  font-size: clamp(80px, calc(6.875rem + ((1vw - 19.2px) * 4.6875)), 110px);
  line-height: clamp(68px, calc(5.5rem + ((1vw - 19.2px) * 3.125)), 88px);
  font-weight: 300;
  width: min-content;
  display: flex;
  text-align: end;
  flex-wrap: wrap;
  span {
    width: min-content;
  }
}

.title2 {
  display: grid;
  font-size: clamp(66px, calc(5.625rem + ((1vw - 19.2px) * 3.75)), 90px);
  font-weight: 300;
  line-height: clamp(56px, calc(4.5rem + ((1vw - 19.2px) * 2.5)), 72px);
  max-width: 694px;
  width: 100%;
  text-align: end;
  text-transform: uppercase;
  margin-left: auto;
  margin-bottom: clamp(32px, calc(3.6875rem + ((1vw - 19.2px) * 4.2188)), 59px);
}

.essence {
  margin-right: auto;
  font-style: normal;
  color: var(--brown2, #ab9788);
}

.epilogue1,
.epilogue3,
list3 {
  color: var(--grey2, #565656);
  font-size: 16px;
  font-weight: 400;
  line-height: 25px; /* 156.25% */
}

.epilogue1 {
  max-width: 310px;
  width: 100%;
}

.epilogue3 {
  max-width: 355px;
  width: 100%;
}

.list3 {
  display: flex;
  flex-direction: column;
  max-width: clamp(662px, calc(58.4375rem + ((1vw - 19.2px) * 42.6563)), 935px);
  width: 100%;
}

.item3 {
  list-style-type: disc;
  line-height: 26px;
  margin-bottom: 17px;
}

.aboutFrameRight {
  display: flex;
  width: var(--distance-to-the-container-far);
  height: clamp(382px, calc(33.75rem + ((1vw - 19.2px) * 24.6875)), 540px);
  object-position: center;
  object-fit: cover;
  margin-left: var(--distance-to-the-container);
  margin-bottom: clamp(
    141px,
    calc(14.125rem + ((1vw - 19.2px) * 13.2813)),
    226px
  );
}

.aboutFrameLeft {
  display: flex;
  width: var(--distance-to-the-container-far);
  height: clamp(382px, calc(32.6875rem + ((1vw - 19.2px) * 22.0313)), 523px);
  object-position: center;
  object-fit: cover;
  margin-right: var(--distance-to-the-container);
  margin-bottom: clamp(32px, calc(3.9375rem + ((1vw - 19.2px) * 4.8438)), 63px);
}

@media (max-width: 1280px) {
  .epilogue1 {
    max-width: 308px;
  }
  .epilogue1,
  .epilogue3,
  .list3 {
    font-size: clamp(12px, calc(1rem + ((1vw - 10.24px) * 0.6024)), 16px);
    line-height: clamp(16px, calc(1.5625rem + ((1vw - 12.8px) * 0.9783)), 25px);
  }

  .title1 {
    font-size: clamp(44px, calc(5rem + ((1vw - 12.8px) * 3.913)), 80px);
    line-height: clamp(36px, calc(4.25rem + ((1vw - 12.8px) * 3.4783)), 68px);
  }

  .title2 {
    font-size: clamp(38px, calc(4.125rem + ((1vw - 12.8px) * 3.0435)), 66px);
    line-height: clamp(32px, calc(3.5rem + ((1vw - 12.8px) * 2.6087)), 56px);
  }
  .list3 {
    max-width: clamp(
      500px,
      calc(41.375rem + ((1vw - 12.8px) * 63.2813)),
      662px
    );
  }

  .aboutFrameLeft,
  .aboutFrameRight {
    height: clamp(224px, calc(23.875rem + ((1vw - 12.8px) * 17.1739)), 382px);
  }
}

@media (max-width: 1024px) {
  .root {
    padding-top: clamp(42px, calc(3.125rem + ((1vw - 10.24px) * 1.2048)), 50px);
    padding-bottom: 5px;
  }
  .performance1,
  .performance2,
  .performance3 {
    flex-direction: column;
  }

  .performance1 {
    max-width: 100%;
    width: 100%;
    margin-bottom: 37px;
  }
  .title1 {
    margin-bottom: 39px;
  }
  .epilogue1 {
    margin-left: auto;
    margin-bottom: 0px;
    max-width: clamp(195px, calc(20rem + ((1vw - 10.24px) * 18.0723)), 320px);
  }
  .performance2 {
    flex-direction: column-reverse;
    transform: none;
    margin-bottom: 50px;
    margin-left: 0;
    * {
      text-align: start;
    }
  }

  .title2 {
    margin-bottom: 18px;
  }
  .list3 {
    max-width: 100%;
    margin-left: 17px;
  }
  .epilogue3 {
    max-width: 100%;
    margin-bottom: 21px;
  }

  .aboutFrameRight {
    margin-bottom: 80px;
  }

  .aboutFrameLeft {
    margin-bottom: 19px;
  }

  .performance3 {
    margin-bottom: 80px;
  }

  .epilogue3,
  .item3 {
    line-height: clamp(20px, calc(1.5625rem + ((1vw - 10.24px) * 0.753)), 25px);
  }

  .item3 {
    margin-bottom: clamp(
      0px,
      calc(1.0625rem + ((1vw - 10.24px) * 2.5602)),
      17px
    );
  }
}

@media (min-width: 1920px) {
  .aboutFrameRight {
    height: 28.13vw;
  }

  .aboutFrameLeft {
    height: 27.24vw;
  }

  .performance3 {
    margin-bottom: 10.68vw;
  }
}
