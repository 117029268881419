.root {
  padding-top: clamp(56px, calc(6.8125rem + ((1vw - 19.2px) * 8.2813)), 109px);
  padding-bottom: clamp(66px, calc(7.125rem + ((1vw - 19.2px) * 7.5)), 114px);
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: clamp(32px, calc(3.75rem + ((1vw - 19.2px) * 4.375)), 60px);
}
.headBlock {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 22px;
}
.aboveTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.title {
  font-size: clamp(80px, calc(6.875rem + ((1vw - 19.2px) * 4.6875)), 110px);
  font-weight: 300;
  line-height: clamp(68px, calc(5.5rem + ((1vw - 19.2px) * 3.125)), 88px);
}

@media (max-width: 1280px) {
  .root {
    padding-top: clamp(42px, calc(3.5rem + ((1vw - 12.8px) * 1.5217)), 56px);
    padding-bottom: clamp(
      5px,
      calc(4.125rem + ((1vw - 12.8px) * 6.6304)),
      66px
    );
  }

  .wrapper {
    gap: clamp(24px, calc(2rem + ((1vw - 12.8px) * 0.8696)), 32px);
  }

  .headBlock {
    gap: clamp(6px, calc(1.375rem + ((1vw - 12.8px) * 1.7391)), 22px);
  }

  .title {
    font-size: clamp(32px, calc(5rem + ((1vw - 12.8px) * 5.2174)), 80px);
    line-height: clamp(28px, calc(4.25rem + ((1vw - 12.8px) * 4.3478)), 68px);
  }

  .aboveTitle {
    font-size: clamp(9px, calc(0.875rem + ((1vw - 12.8px) * 0.5435)), 14px);
    line-height: clamp(
      11.7px,
      calc(1.375rem + ((1vw - 12.8px) * 1.1196)),
      22px
    );
  }
}

@media (max-width: 1024px) {
  .aboveTitle {
    font-weight: 300;
  }

  .wrapper {
    gap: clamp(14px, calc(3.25rem + ((1vw - 10.24px) * 5.7229)), 52px);
  }
}
