.modalOverlay {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 7;

  .modalWindow {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    position: absolute;
    top: 0;

    background-color: white;
    top: -100%;
    transition: top 0.3s ease;
  }

  .activemodalWindow {
    top: 0;
  }

  .btnExit {
    position: absolute;
    background: transparent;
    top: 16px;
    right: 16px;
  }
}

@media (max-width: 850px) {
  .modalOverlay {
    display: flex;
  }
}
