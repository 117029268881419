.persons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0px;
}

.scrollPersons {
  display: none;
}

@media (max-width: 1024px) {
  .persons {
    display: none;
  }

  .scrollPersons {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .buttonsArow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .buttonLeft,
  .buttonRight {
    background: transparent;
  }

  .buttonLock {
    stroke: #b9b8b8;
  }
}
