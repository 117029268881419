.root {
  padding-top: clamp(57px, calc(4.8125rem + ((1vw - 19.2px) * 3.125)), 77px);
  color: var(--black, #010005);
  padding-bottom: clamp(54px, calc(5.75rem + ((1vw - 19.2px) * 5.9375)), 92px);
}

.headerWrapper {
  display: grid;
  grid-template-columns:
    clamp(127px, calc(12rem + ((1vw - 19.2px) * 10.1563)), 192px)
    auto clamp(127px, calc(12rem + ((1vw - 19.2px) * 10.1563)), 192px);
  gap: 0;

  margin-bottom: clamp(37px, calc(3.75rem + ((1vw - 19.2px) * 3.5938)), 60px);
}

.direction {
  position: relative;
  align-self: baseline;
  font-size: 12px;
  color: var(--black);
  font-weight: 400;
  line-height: 18.6px;
  padding: 0px 22px 1px 22px;
  text-transform: uppercase;
  background: transparent;
}

.descriptionList {
  li {
    list-style-type: disc;
    margin-left: 16px;
  }
}

.back {
  justify-self: start;
  margin-left: -3px;
}

.forward {
  justify-self: end;
  margin-right: -3px;
}

.direction:disabled {
  color: var(--grey3);
}

.direction:disabled:hover {
  cursor: default;
}

.direction::after {
  content: "";
  position: absolute;
  display: block;
  width: 75px;
  height: 1px;
  background: var(--black, #010005);
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.4s;
}

.direction:disabled::after {
  background: var(--grey3);
}

.direction:hover::after {
  width: 90px;
}

.direction:disabled:hover::after {
  width: 80px;
}

.title {
  color: var(--black, #010005);
  font-family: "Cormorant Garamond";
  font-size: clamp(60px, calc(5rem + ((1vw - 19.2px) * 3.125)), 80px);
  font-weight: 400;
  line-height: clamp(56px, calc(5rem + ((1vw - 19.2px) * 3.75)), 80px);
  text-align: center;
  text-transform: uppercase;
}

.noWrap {
  white-space: nowrap;
}

.showTitle2 {
  display: none;
}

.tablet {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  color: var(--grey2, #565656);
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;

  margin-bottom: clamp(59px, calc(5.5rem + ((1vw - 19.2px) * 4.5313)), 88px);
}

.tabletLeft {
  display: flex;
  flex-direction: row;
  gap: 50px;
  height: fit-content;
}

.property {
  display: flex;
  flex-direction: row;
  gap: 27px;

  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  p {
    max-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.tabletRight {
  max-width: clamp(464px, calc(37.9375rem + ((1vw - 19.2px) * 22.3438)), 607px);
  width: 100%;
  gap: 0px;
  display: flex;
  flex-direction: column;
}

.pict {
  display: flex;
  width: 100%;
  height: clamp(428px, calc(40.25rem + ((1vw - 19.2px) * 33.75)), 644px);
  object-position: center;
  object-fit: cover;
  max-width: var(--distance-to-the-container-far);
}

.pictTop {
  margin-left: auto;
  margin-bottom: clamp(39px, calc(3.75rem + ((1vw - 19.2px) * 3.2813)), 60px);
}
.pictBottom {
  margin-right: auto;
  margin-bottom: clamp(73px, calc(6.625rem + ((1vw - 19.2px) * 5.1563)), 106px);
}

.showButton {
  display: flex;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 24.8px; /* 177.143% */
  text-transform: uppercase;
  padding: 8px 16px;
  border: 1px solid var(--night);
  color: var(--night);
  transition: 0.4s;
  margin: 0 auto;
  margin-bottom: 73px;
}

.showButton:hover {
  background-color: var(--brown2);
  color: var(--light);
}

.flipWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  margin-top: 0px;
}

.flipWrapperShow {
  margin-top: 65px;
}

@media (max-width: 1520px) {
}

@media (max-width: 1280px) {
  .root {
    padding-top: clamp(44px, calc(3.4375rem + ((1vw - 12.8px) * 1.1957)), 55px);
    padding-bottom: clamp(
      10px,
      calc(3.4375rem + ((1vw - 12.8px) * 4.8913)),
      55px
    );
  }
  .title {
    font-size: clamp(26px, calc(3.75rem + ((1vw - 12.8px) * 3.6957)), 60px);
    line-height: clamp(22px, calc(3.5rem + ((1vw - 12.8px) * 3.6957)), 56px);
    text-align: center;
  }

  .direction {
    font-size: clamp(10px, calc(0.75rem + ((1vw - 12.8px) * 0.2174)), 12px);
    line-height: clamp(14px, calc(1.1625rem + ((1vw - 12.8px) * 0.5)), 18.6px);
  }

  .tablet {
    font-size: clamp(10px, calc(0.75rem + ((1vw - 12.8px) * 0.2174)), 12px);
    line-height: clamp(
      15.6px,
      calc(1.1875rem + ((1vw - 12.8px) * 0.3696)),
      19px
    );
  }

  .property {
    img {
      width: clamp(36px, calc(3.125rem + ((1vw - 12.8px) * 1.5217)), 50px);
      height: clamp(36px, calc(3.125rem + ((1vw - 12.8px) * 1.5217)), 50px);
    }
  }

  .pict {
    height: clamp(224px, calc(26.75rem + ((1vw - 10.24px) * 30.7229)), 428px);
  }

  .flipWrapperShow {
    margin-bottom: 27px;
  }
}

@media (max-width: 1200px) {
  .tabletLeft {
    flex-direction: column;
    gap: 22px;
    .property {
      align-items: center;
      gap: 16px;

      p {
        width: 115px;
      }
    }
    margin-bottom: clamp(29px, calc(4.6875rem + ((1vw - 12px) * 5.4762)), 75px);
  }
}

@media (max-width: 1024px) {
  .direction {
    padding: 1px 7px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .direction::after {
    width: 65px;
  }

  .headerWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: clamp(
      32px,
      calc(4.6875rem + ((1vw - 10.24px) * 6.4759)),
      75px
    );
  }
  .title {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: clamp(
      24px,
      calc(4.6875rem + ((1vw - 10.24px) * 7.6807)),
      75px
    );
  }
  .showTitle1 {
    display: none;
  }
  .showTitle2 {
    display: flex;
  }

  .pictTop {
    margin-bottom: clamp(
      18px,
      calc(3.75rem + ((1vw - 10.24px) * 6.3253)),
      60px
    );
  }

  .tablet {
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: 100%;
  }

  .tabletRight {
    max-width: 100%;
    gap: 0px;
    margin-bottom: clamp(
      39px,
      calc(4.6875rem + ((1vw - 10.24px) * 5.4217)),
      75px
    );
  }
}

@media (min-width: 1920px) {
  .pict {
    height: 33.54vw;
  }
}
