.root {
  padding-top: clamp(
    122px,
    calc(10.4375rem + ((1vw - 19.2px) * 7.0313)),
    167px
  );
  padding-bottom: clamp(66px, calc(7rem + ((1vw - 19.2px) * 7.1875)), 112px);
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.services {
  font-size: clamp(64px, calc(4.625rem + ((1vw - 19.2px) * 1.5625)), 74px);
  line-height: clamp(64px, calc(4rem + ((1vw - 19.2px) * -0.625)), 68px);
  letter-spacing: 1px;
  margin-right: clamp(
    135px,
    calc(18.125rem + ((1vw - 19.2px) * 24.2188)),
    290px
  );
  margin-top: 0;
  color: var(--night);
}

.kinds {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid var(--grey2);
}

@media (max-width: 1280px) {
  .root {
    padding-bottom: clamp(
      5px,
      calc(4.125rem + ((1vw - 12.8px) * 6.6304)),
      66px
    );
  }

  .services {
    letter-spacing: 1px;
    font-size: clamp(34px, calc(4rem + ((1vw - 12.8px) * 3.2609)), 64px);
    line-height: clamp(34px, calc(4.25rem + ((1vw - 12.8px) * 3.6957)), 68px);
  }

  .tip {
    letter-spacing: -0.5px;
  }

  .description {
    padding: px 0px 0px 12px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 1024px) {
  .root {
    padding-top: 35px;
  }
  .services {
    letter-spacing: 0px;
    margin-bottom: 27px;
  }
  .line {
    margin-bottom: 3px;
  }
  .wrapper {
    flex-direction: column;
  }
}
