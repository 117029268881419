.trio,
.twoBig {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: clamp(52px, calc(4.75rem + ((1vw - 19.2px) * 3.75)), 76px);
  color: var(--grey2);
}

.reverse {
  direction: rtl;
}

.two {
  display: flex;
  flex-direction: column;
  gap: 33px;
}

.twoReverse {
  flex-direction: column-reverse;
}

@media (max-width: 1280px) {
  .two {
    gap: 38px;
  }
}

@media (max-width: 1024px) {
  .trio {
    display: flex;
    flex-direction: column;
  }

  .twoBig,
  .trio,
  .two {
    gap: clamp(14px, calc(3.25rem + ((1vw - 10.24px) * 5.7229)), 52px);
    margin-bottom: 0px;
  }
  .trio img {
    object-fit: cover;
    max-width: none;
  }

  .two,
  .twoBig {
    display: flex;
    flex-direction: column;
  }

  .reverse {
    flex-direction: column-reverse;
  }

  .twoReverse {
    flex-direction: column-reverse;
  }
}

@media (min-width: 1920px) {
  .two {
    gap: 1.72vw;
  }
}
