.sliderWrapper {
  position: relative;
  display: flex;
  width: var(--distance-to-the-container-far);
  height: clamp(444px, calc(39.25rem + ((1vw - 19.2px) * 28.75)), 628px);
  margin-left: auto;
  margin-bottom: clamp(60px, calc(3.75rem + ((1vw - 19.2px) * -1.0937)), 67px);
}

@media (max-width: 1280px) {
  .sliderWrapper {
    height: clamp(14rem, 8.62rem + 23.91vw, 27.75rem);
  }
}

@media (max-width: 1024px) {
  .sliderWrapper {
    margin-bottom: 17px;
  }
}

@media (min-width: 1920px) {
  .sliderWrapper {
    height: 32.71vw;
    margin-bottom: 3.125vw;
  }
}
