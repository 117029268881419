.root {
  flex: 0 1 auto;
  background: var(--light);
  padding-top: clamp(69px, calc(4.6875rem + ((1vw - 19.2px) * 0.9375)), 75px);
  padding-bottom: 93px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr auto auto;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.58333;
}

.menus {
  display: flex;
  padding-right: 301px;
}

.first {
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
}

.first,
.second,
.third,
.fourth {
  color: var(--grey3);
  display: flex;
  flex-direction: column;
}

.deviart {
  color: var(--grey3);
  transition: 0.5s ease-in-out;
  &:hover {
    color: var(--night);
    text-decoration: underline;
  }
}

.first {
  max-width: 300px;
  width: 100%;
  font-weight: 400;
}

.first img {
  max-width: 293px;
  width: 100%;
  margin-bottom: clamp(
    49px,
    calc(3.0625rem + ((1vw - 19.2px) * -0.9375)),
    55px
  );
}

.portfolio,
.contacts {
  color: var(--brown2, #ab9788);
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 1px;
  margin-bottom: 7px;
}

.third {
  padding-left: 90px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.listContacts {
  color: var(--grey3, #9e9e9e);
  gap: 3px;
}

.goTop {
  position: relative;
  background: transparent;
  margin: -2px 17px auto 100px;
  padding-bottom: 10px;
  color: var(--black, #010005);
}

.goTop::after {
  content: "";
  position: absolute;
  display: block;
  width: 125%;
  height: 1px;
  background-color: var(--black, #010005);
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.4s;
}

.goTop:hover::after {
  width: 150%;
}

.item {
  color: var(--brown2, #ab9788);
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}
.itemContacts {
  color: var(--grey3, #9e9e9e);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

@media (max-width: 1280px) {
  .root {
    padding-bottom: clamp(
      37px,
      calc(5.8125rem + ((1vw - 19.2px) * 3.5897)),
      93px
    );
  }

  .wrapper {
    grid-template-columns: 1fr auto 157px;
  }
  .menus {
    padding-right: 0;
  }

  .first {
    font-size: clamp(9px, calc(0.75rem + ((1vw - 12.8px) * 0.3261)), 12px);
    line-height: clamp(9px, calc(1.1875rem + ((1vw - 12.8px) * 1.087)), 19px);
  }

  .portfolio,
  .contacts {
    font-size: clamp(12px, calc(0.875rem + ((1vw - 12.8px) * 0.2174)), 14px);
    margin-top: 2px;
    margin-bottom: 11px;
  }

  .item {
    font-size: clamp(10px, calc(0.75rem + ((1vw - 12.8px) * 0.2174)), 12px);
    line-height: 18px;
  }

  .fourth {
    margin-right: 11px;
    letter-spacing: -1px;
  }

  .goTop {
    font-size: clamp(10px, calc(0.75rem + ((1vw - 12.8px) * 0.2174)), 12px);
    line-height: clamp(
      13px,
      calc(1.1625rem + ((1vw - 12.8px) * 0.6087)),
      18.6px
    );
    width: fit-content;
  }
}

@media (max-width: 1024px) {
  .root {
    padding-top: clamp(
      69px,
      calc(4.3125rem + ((1vw - 10.24px) * -1.9578)),
      82px
    );
  }
  .wrapper {
    grid-template-columns: 1fr;
  }

  .first,
  .menus,
  .fourth {
    margin: 0 auto;
    padding: 0;
  }

  .first {
    margin-bottom: 28px;
  }

  .first * {
    display: flex;
    margin: 0 auto;
  }

  .first img {
    max-width: clamp(
      200px,
      calc(17.1875rem + ((1vw - 12.8px) * 8.1522)),
      275px
    );
    width: 100%;
    max-height: 32px;
    height: 100%;
    margin-bottom: 18px;
  }
  .entitlement {
    margin-bottom: 3px;
  }

  .menus,
  .second,
  .third {
    margin: 0;
    padding: 0;
  }

  .menus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 clamp(17px, calc(12.5rem + ((1vw - 10.24px) * 27.5602)), 200px);
  }

  .portfolio,
  .contacts {
    margin: 0;
    padding: 0;
  }

  .goTop {
    margin: 0;
  }
}

@media (max-width: 480px) {
  .third {
    padding-left: 84px;
  }

  .fourth {
    margin-top: 40px;
  }
}
