.concept {
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  padding: clamp(20px, calc(1.875rem + ((1vw - 19.2px) * 1.5625)), 30px);
}

.title {
  font-size: clamp(44px, calc(3.75rem + ((1vw - 19.2px) * 2.5)), 60px);
  font-weight: 400;
  line-height: clamp(44px, calc(4rem + ((1vw - 19.2px) * 3.125)), 64px);
  text-transform: uppercase;
  // margin-bottom: clamp(59px, calc(6.25rem + ((1vw - 19.2px) * 6.4063)), 100px);
  margin-bottom: clamp(30px, calc(3.75rem + ((1vw - 19.2px) * 1.9231)), 60px);
  max-width: min-content;
}

.list {
  font-size: clamp(14px, calc(1rem + ((1vw - 19.2px) * 0.3125)), 16px);
  line-height: clamp(22px, calc(1.625rem + ((1vw - 19.2px) * 0.625)), 26px);
}

.item {
  list-style-type: disc;
  margin-left: 13px;
  margin-bottom: clamp(15px, calc(1.5rem + ((1vw - 19.2px) * 1.4063)), 24px);
}

.item:last-child {
  margin-bottom: 0;
}

@media (max-width: 1280px) {
  .title {
    // font-size: clamp(2.25rem, 2.054rem + 0.87vw, 2.75rem);
    font-size: clamp(36px, calc(2.75rem + ((1vw - 12.8px) * 3.125)), 44px);
    line-height: 1;
  }
  .list {
    font-size: clamp(12px, calc(0.875rem + ((1vw - 12.8px) * 0.2174)), 14px);
    line-height: clamp(20px, calc(1.375rem + ((1vw - 10.24px) * 0.3012)), 22px);
  }
}

@media (max-width: 1024px) {
  .concept {
    display: flex;
    margin: 0 auto;
    margin-bottom: 0px;
  }

  .item {
    margin-left: 7px;
    margin-bottom: 18px;
  }
}

@media (min-width: 1921px) {
  .title {
    margin-bottom: 3.125vw;
  }
}
