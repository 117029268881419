.root {
  padding-top: clamp(126px, calc(7.875rem + ((1vw - 19.2px) * -2.1875)), 140px);
  padding-bottom: clamp(
    145px,
    calc(10.375rem + ((1vw - 19.2px) * 3.2813)),
    166px
  );
  color: var(--grey3);
}

.wrapper {
  position: relative;
  margin: 0 auto;
}

.wrapper::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--grey2);
  bottom: clamp(-144px, calc(-9rem + ((1vw - 19.2px) * -7.1875)), -98px);
}

.performance {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: clamp(
    81px,
    calc(5.0625rem + ((1vw - 19.2px) * -1.0937)),
    88px
  );
  color: var(--grey3);
  font-size: 12px;
  font-weight: 400;
  line-height: clamp(
    15.6px,
    calc(1.1625rem + ((1vw - 19.2px) * 0.4688)),
    18.6px
  );
  text-transform: uppercase;
  transition: transform 0.7s;
}

.performance::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--grey2);
  bottom: clamp(-38px, calc(-1.125rem + ((1vw - 19.2px) * 3.125)), -18px);
}

.works,
.get {
  color: var(--grey2);
  margin: auto 0;
}

.works {
  width: 155px;
  margin-right: 10px;
}

.get {
  position: relative;
  margin-right: 20px;
  padding-bottom: 6px;
}

.down {
  display: none;
}

.get::after {
  content: "";
  position: absolute;
  display: block;
  width: 105px;
  height: 1px;
  background-color: var(--grey2);
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.4s;
}

.get:hover::after {
  width: 119px;
}

.title {
  color: var(--night);
  font-size: clamp(48px, calc(4rem + ((1vw - 19.2px) * 2.5)), 64px);
  line-height: clamp(48px, calc(3.75rem + ((1vw - 19.2px) * 1.875)), 60px);
  margin-right: clamp(48px, calc(3.9375rem + ((1vw - 19.2px) * 2.3438)), 63px);
  text-transform: uppercase;
}

@media (max-width: 1280px) {
  .title {
    display: flex;
    justify-content: center;
    letter-spacing: -1px;
    font-size: clamp(30px, calc(3rem + ((1vw - 12.8px) * 1.9565)), 48px);
    line-height: 1;
  }

  .works {
    font-size: clamp(9px, calc(0.75rem + ((1vw - 12.8px) * 0.3261)), 12px);
    line-height: clamp(
      11.7px,
      calc(0.975rem + ((1vw - 12.8px) * 0.4239)),
      15.6px
    );
  }
}

@media (max-width: 1024px) {
  .root {
    padding-top: clamp(
      82px,
      calc(10.9375rem + ((1vw - 10.24px) * 14.006)),
      175px
    );
    padding-bottom: clamp(
      78px,
      calc(9.0625rem + ((1vw - 10.24px) * 10.0904)),
      145px
    );
  }
  .right {
    display: none;
  }
  .down {
    display: inline;
    margin-left: 17px;
  }

  .wrapper::after {
    bottom: -57px;
  }

  .performance {
    display: flex;
    flex-direction: column;
    font-weight: 300;
    margin-bottom: clamp(
      55px,
      calc(6.25rem + ((1vw - 10.24px) * 6.7771)),
      100px
    );
  }
  .performance::after {
    bottom: -23px;
  }

  .title {
    margin: 0;
    text-align: center;
  }

  .works,
  .get {
    font-weight: 300;
    font-size: clamp(9px, calc(0.75rem + ((1vw - 12.8px) * 0.3261)), 12px);
    line-height: clamp(
      11.7px,
      calc(0.975rem + ((1vw - 19.2px) * 0.6094)),
      15.6px
    );
  }

  .get {
    margin-left: 5px;
    letter-spacing: 1px;
    display: inline;
  }

  .works {
    margin: 0 auto;
    width: fit-content;
    margin-bottom: 7px;
  }

  .right {
    display: none;
  }

  .left {
    flex-direction: column;
    flex: 1;
  }

  .outputThree {
    margin-bottom: 58px;
  }

  .down {
    display: inline;
    margin-left: 17px;
  }
}
