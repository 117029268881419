.root {
}

.wrapper {
  max-width: clamp(1150px, calc(72.5rem + ((1vw - 19.2px) * 1.5625)), 1160px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: clamp(10px, calc(1.25rem + ((1vw - 19.2px) * 1.5625)), 20px);
}

.rowOne {
  img {
    width: 100%;
    height: 718px;
    object-position: center;
    object-fit: cover;
  }
}

.rowTwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: clamp(10px, calc(1.25rem + ((1vw - 19.2px) * 1.5625)), 20px);
  img {
    width: 100%;
    height: 760px;
    object-position: center;
    object-fit: cover;
  }
}

@media (max-width: 1280px) {
  .wrapper {
    max-width: 100%;
    margin-left: var(--distance-to-the-container);
    margin-right: var(--distance-to-the-container);
    gap: clamp(10px, calc(0.625rem + ((1vw - 12.8px) * -0.8696)), 18px);
  }

  .rowOne {
    img {
      height: clamp(224px, calc(44.875rem + ((1vw - 12.8px) * 53.6957)), 718px);
    }
  }

  .rowTwo {
    gap: clamp(10px, calc(1.25rem + ((1vw - 19.2px) * 1.5625)), 20px);
    img {
      height: clamp(208px, calc(47.5rem + ((1vw - 12.8px) * 60)), 760px);
    }
  }
}

@media (max-width: 1024px) {
  .wrapper {
    margin: 0;
  }

  .rowTwo {
    margin-left: var(--distance-to-the-container);
    margin-right: var(--distance-to-the-container);
  }
}

@media (min-width: 1920px) {
  .wrapper {
    max-width: 60.42vw;
  }
  .rowOne {
    height: 37.4vw;
    img {
      height: 100%;
    }
  }
  .rowTwo {
    height: 39.58vw;
    img {
      height: 100%;
    }
  }
}
