.bottomCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.bottomCardReverse {
  flex-direction: row-reverse;
  text-align: end;
}

.inDetail {
  display: none;
  color: var(--black, #010005);
  font-size: 9px;
  font-weight: 400;
  line-height: 12px;
  padding: 4px 12px;
  background: transparent;
  border: 0.5px solid var(--black, #010005);
  margin: 7px 0 auto 20px;
}

.bigImg {
  height: 820px;
}

.veryBigImg {
  height: 820px;
  width: 100%;
}

.smolImg {
  height: 360px;
}

.smolImg,
.bigImg {
  width: 100%;
  object-position: center;
  object-fit: cover;
  transition: transform 0.4s;
}

.smolImg:hover,
.bigImg:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.coment1 {
  // font-family: "Cormorant Infant";
  font-size: 16px;
  line-height: 25px; /* 156.25% */
  font-weight: 400;
  color: var(--night);
  margin-top: 20px;
  text-transform: uppercase;
}

.coment2 {
  font-size: 14px;
  line-height: 22px; /* 157.143% */
  font-weight: 400;
  color: var(--grey2);
}

.coment1,
.coment2 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@media (max-width: 1280px) {
  .coment1 {
    font-size: clamp(13px, calc(0.875rem + ((1vw - 12.8px) * 0.1087)), 14px);
    line-height: normal;
  }
  .coment2 {
    font-size: 12px;
  }
}

@media (max-width: 1024px) {
  .bigImg,
  .smolImg {
    height: clamp(154px, calc(26.25rem + ((1vw - 10.24px) * 40.0602)), 420px);
  }

  .coment1 {
    margin-top: 7px;
    -webkit-line-clamp: 3;
  }
  .coment2 {
    display: none;
  }
}

@media (max-width: 480px) {
  .bigImg,
  .smolImg {
    pointer-events: none; /* Отключаем события мыши */
    cursor: default; /* Изменяем курсор на стандартный */
  }

  .inDetail {
    display: flex;
  }
}

@media (max-width: 360px) {
  .architectureSize {
    font-size: 12px;
  }
}

@media (min-width: 1920px) {
  .bigImg {
    height: 42.71vw;
  }

  .veryBigImg {
    height: 42.71vw;
  }

  .smolImg {
    height: calc(
      18.75vw + clamp(0px, calc(0rem + ((1vw - 19.2px) * 1.7722)), 28px)
    );
  }
}
