.conceptsShow {
  display: none;
}

.bottomBlock {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(20px, calc(1.875rem + ((1vw - 19.2px) * 1.5625)), 30px);
}

@media (max-width: 1280px) {
}

@media (max-width: 1024px) {
  .bottomBlock {
    display: none;
  }

  .conceptsShow {
    display: flex;
    flex-direction: column;
  }

  .buttonsArow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 18px;
    margin-bottom: 9px;
  }

  .buttonLeft,
  .buttonRight {
    background: transparent;
  }

  .buttonLock {
    stroke: #b9b8b8;
  }
}

@media (max-width: 360px) {
}
