.root {
  padding-top: clamp(15px, calc(4.6875rem + ((1vw - 19.2px) * 9.375)), 75px);
  padding-bottom: clamp(
    54px,
    calc(5.4375rem + ((1vw - 19.2px) * 5.1563)),
    87px
  );
  color: var(--grey2, #565656);
  h1 {
    color: var(--black, #010005);
  }
  h2 {
    color: var(--brown2, #ab9788);
  }
}

.performance1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: clamp(38px, calc(3.9375rem + ((1vw - 19.2px) * 3.9063)), 63px);
}

.title1 {
  font-size: clamp(80px, calc(6.875rem + ((1vw - 19.2px) * 4.6875)), 110px);
  line-height: clamp(68px, calc(5.5rem + ((1vw - 19.2px) * 3.125)), 88px);
  display: flex;
  flex-direction: column;
  text-align: start;
  flex-wrap: wrap;
  white-space: nowrap;
}

.essence {
  font-style: normal;
  color: var(--brown2);
}

.epilogue1 {
  max-width: 310px;
  width: 100%;
  padding-top: 0;
}

.epilogue1,
.midlBlock {
  color: var(--grey2, #565656);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 156.25% */
}

.midlBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: clamp(
    122px,
    calc(12.9375rem + ((1vw - 19.2px) * 13.2813)),
    207px
  );
  h3 {
    font-family: Montserrat;
    letter-spacing: -2px;
  }
}

.midlBlock {
  line-height: 26px;
  ul {
    padding-left: 25px;
    li {
      margin-bottom: 16px;
      list-style-type: disc;
    }
  }
}

.midlLeft {
  max-width: 345px;
  width: 100%;
}
.midlRight {
  max-width: clamp(720px, calc(60rem + ((1vw - 19.2px) * 37.5)), 960px);
  width: 100%;
}

.midlLeft,
.midlRight {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1280px) {
  .title1 {
    font-size: clamp(44px, calc(5rem + ((1vw - 12.8px) * 3.913)), 80px);
    line-height: clamp(36px, calc(4.25rem + ((1vw - 12.8px) * 3.4783)), 68px);
  }
  .epilogue1 {
    font-size: clamp(12px, calc(1rem + ((1vw - 19.2px) * 0.625)), 16px);
    line-height: clamp(
      15.6px,
      calc(1.5625rem + ((1vw - 19.2px) * 1.4688)),
      25px
    );
  }

  .midlBlock {
    font-size: clamp(12px, calc(1rem + ((1vw - 12.8px) * 0.4348)), 16px);
    line-height: clamp(20px, calc(1.625rem + ((1vw - 19.2px) * 0.9375)), 26px);
  }
}

@media (max-width: 1024px) {
  .root {
    padding-top: 42px;
    padding-bottom: 0px;
  }

  .performance1 {
    margin-bottom: clamp(
      2px,
      calc(1.875rem + ((1vw - 10.24px) * 4.2169)),
      30px
    );
  }
  .performance1,
  .midlBlock {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  .title1 {
    margin-bottom: 38px;
  }

  .epilogue1 {
    margin-left: auto;
    max-width: clamp(
      196px,
      calc(19.375rem + ((1vw - 10.24px) * 17.1687)),
      310px
    );
    margin-bottom: 38px;
  }

  .midlLeft {
    max-width: 100%;
    margin-bottom: clamp(30px, calc(3.125rem + ((1vw - 19.2px) * 3.125)), 50px);
    margin-left: 9px;
  }

  .midlRight {
    max-width: 100%;
    margin-bottom: clamp(
      34px,
      calc(3.125rem + ((1vw - 10.24px) * 2.4096)),
      50px
    );
    padding-left: 0px;
  }

  .midlBlock {
    padding-left: 0;
    margin-bottom: clamp(
      30px,
      calc(4.6875rem + ((1vw - 10.24px) * 6.7771)),
      75px
    );
    margin-left: -9px;
  }

  .concept {
    margin-bottom: 15px;
  }
}

@media (max-width: 550px) {
  .essence {
    white-space: pre-wrap;
    white-space: break-spaces;
  }
}

@media (max-width: 360px) {
  .midlBlock {
    // line-height: 15.9px;
    h3 {
      font-size: 12px;
      letter-spacing: normal;
    }
  }
}

@media (min-width: 1920px) {
}
