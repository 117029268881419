:root {
  --gold: #a78b73;
  --light: #ffffff;
  --white: #fff;
  --grey: #dad6d2;
  --grey2: #565656;
  --grey3: #9e9e9e;
  --grey4: #595959;
  --dark: #0d0d0d;
  --night: #010005;

  --brown: #ca8f65;
  --brown2: #ab9788;
}

// Cтарый
// --grey: #8f8f8f;
