.appWrapper {
  max-width: 1940px;
  margin: 0 auto;
}

* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  width: 100%;
  height: 100%;
  font-size: 1rem;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a {
  // color: black;
  transition: width 0.4s;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  max-width: 100%;
  vertical-align: top;
}
