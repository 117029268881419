@import "./variables";
@import "./fonts";

body {
  font-family: Montserrat;
  font-weight: 300;
  background: var(--light);
  transition: background-color 0.9s ease-out;
  height: 100%;
}

.switchBackgroundColor {
  background: #0d0d0d;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cormorant Garamond";
  font-weight: 300;
}

.container {
  width: calc(
    100vw - clamp(40px, calc(12rem + ((1vw - 19.2px) * 9.7436)), 192px)
  );
  margin: 0 auto;
}
