.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
