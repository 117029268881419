.wrapper {
  padding: 35px;
  width: 100vw;
  background: var(--light);
  display: flex;
  flex-direction: column;
}

.navBlock {
  margin-bottom: 78px;
}

.social {
  display: flex;
  align-items: center;
}
