.person {
  display: flex;
  flex-direction: column;
  max-width: clamp(370px, calc(34.75rem + ((1vw - 19.2px) * 29.0625)), 556px);
  width: 100%;
  height: 100%;
  transition: 0.4s;
}

.person:hover {
  box-shadow: 2px 5px 5px 2px gray;
}

.photo {
  object-position: center;
  object-fit: cover;
  width: 100%;
  margin-bottom: clamp(30px, calc(2.5rem + ((1vw - 19.2px) * 1.5625)), 40px);
}

.description {
  padding: 0 10px;
}

.fio {
  font-style: normal;
  color: var(--brown2);
  font-size: clamp(48px, calc(3.75rem + ((1vw - 19.2px) * 1.875)), 60px);
  font-weight: 300;
  line-height: clamp(52px, calc(4rem + ((1vw - 19.2px) * 1.875)), 64px);
  text-align: start;
  text-transform: uppercase;
  margin-bottom: clamp(16px, calc(1.25rem + ((1vw - 19.2px) * 0.625)), 20px);
}

.item {
  color: var(--grey2, #565656);
  list-style-type: disc;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-left: 25px;
  margin-bottom: 17px;
}

@media (max-width: 1280px) {
  .person {
    max-width: clamp(
      295px,
      calc(23.125rem + ((1vw - 12.8px) * 29.2969)),
      370px
    );
  }

  .photo {
    margin-bottom: clamp(
      24px,
      calc(1.875rem + ((1vw - 10.24px) * 0.9036)),
      30px
    );
  }

  .fio {
    font-size: clamp(36px, calc(3rem + ((1vw - 12.8px) * 1.3043)), 48px);
    line-height: clamp(36px, calc(3.25rem + ((1vw - 12.8px) * 1.7391)), 52px);
  }

  .item {
    font-size: clamp(12px, calc(1rem + ((1vw - 12.8px) * 0.4348)), 16px);
    line-height: clamp(20px, calc(1.625rem + ((1vw - 12.8px) * 0.6522)), 26px);
  }
}

@media (max-width: 1024px) {
  .person {
    max-width: clamp(
      324px,
      calc(34.75rem + ((1vw - 10.24px) * 34.9398)),
      556px
    );
    margin: 0 auto;
  }
  .item {
    margin-left: 15px;
  }
}

@media (max-width: 360px) {
  .person {
    max-width: 100%;
  }
}

@media (min-width: 1920px) {
  .person {
    max-width: 28.96vw;
  }
}
