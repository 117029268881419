.social {
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-self: center;
}

.social img {
  width: 25px;
  transition: width 0.4s;
}

.social img:hover {
  cursor: pointer;
}

.phone {
  background: white;
  width: 25px;
  height: 25px;
}

@media (max-width: 1280px) {
  .social img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  .phone {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 850px) {
  .social {
    gap: 20px;
    margin: 0 auto;

    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
    }
  }
}
