.root {
  padding-top: clamp(81px, calc(6.5625rem + ((1vw - 19.2px) * 3.75)), 105px);
  margin-bottom: auto;
}

.wrapper {
  position: relative;
  height: clamp(560px, calc(49.9375rem + ((1vw - 19.2px) * 37.3438)), 799px);
}

.wrapper::after {
  content: "";
  background-image: url("../../assets/img/contactsPage/1920Contacts.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  height: clamp(410px, calc(37.1875rem + ((1vw - 19.2px) * 28.9063)), 595px);
  width: var(--distance-block);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.prb {
  height: 100%;
}
.content {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.left {
  display: contents;
}

.performance {
  display: flex;
  flex-direction: row;
  margin-right: auto;
}

.performance {
  display: contents;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--dark);
  font-size: clamp(66px, calc(5.625rem + ((1vw - 19.2px) * 3.75)), 90px);
  font-weight: 300;
  line-height: clamp(56px, calc(4.5rem + ((1vw - 19.2px) * 2.5)), 72px);
  max-width: fit-content;
  width: 100%;
  margin-left: auto;
  em {
    color: var(--brown2);
    margin-left: auto;
    font-style: normal;
  }
}
.right {
  max-width: clamp(368px, calc(32.375rem + ((1vw - 19.2px) * 23.4375)), 518px);
  width: 100%;
}

.contactsFrame,
.down {
  display: none;
}

@media (max-width: 1280px) {
  .right {
    max-width: clamp(250px, calc(23rem + ((1vw - 12.8px) * 46.0938)), 368px);
  }
  .title {
    font-size: clamp(38px, calc(4.125rem + ((1vw - 12.8px) * 10.9375)), 66px);
    line-height: clamp(32px, calc(3.5rem + ((1vw - 12.8px) * 9.375)), 56px);
  }

  .wrapper {
    height: clamp(450px, calc(35rem + ((1vw - 12.8px) * 42.9688)), 560px);
  }

  .wrapper::after {
    height: clamp(350px, calc(25.625rem + ((1vw - 12.8px) * 23.4375)), 410px);
  }
}

@media (max-width: 1024px) {
  .root {
    padding-top: clamp(
      41px,
      calc(5.0625rem + ((1vw - 10.24px) * 6.0241)),
      81px
    );
  }

  .wrapper {
    height: unset;
  }
  .wrapper::after {
    display: none;
  }

  .title {
    margin-left: auto;
    margin-bottom: clamp(
      19px,
      calc(3.125rem + ((1vw - 10.24px) * 4.6687)),
      50px
    );
  }

  .content {
    height: unset;
  }

  .right {
    display: none;
  }

  .contactsFrame {
    display: flex;
    max-width: var(--interval-container);
    height: clamp(224px, calc(29.375rem + ((1vw - 10.24px) * 37.0482)), 470px);
    width: 100%;
    object-position: center;
    object-fit: cover;
    margin-bottom: clamp(
      20px,
      calc(3.125rem + ((1vw - 10.24px) * 4.5181)),
      50px
    );
  }

  .phone {
    align-items: end;
  }

  .down {
    display: flex;
    margin-bottom: 0px;
  }
}

@media (max-width: 360px) {
  .root {
    margin-bottom: auto;
  }
}

@media (min-width: 1921px) {
  .wrapper {
    height: calc(
      41.61vw + clamp(0px, calc(0rem + ((1vw - 10.24px) * 3.0291)), 75px)
    );
    //
  }

  .wrapper::after {
    height: calc(
      30.99vw + clamp(0px, calc(0rem + ((1vw - 10.24px) * 4.8465)), 120px)
    );

    // background-size: contain;
  }
}
