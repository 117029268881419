.root {
  padding-top: clamp(0px, calc(0rem + ((1vw - 19.2px) * -3.2812)), 21px);
  position: relative;
  display: block;
  width: 100%;
  min-height: calc(
    100vh - clamp(134px, calc(8.75rem + ((1vw - 19.2px) * 0.9375)), 140px)
  );
  background-image: url("../../../assets/img/mainHero/hero1280.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;

  &::before {
    //тень
    content: "";
    position: absolute;
    width: 100%;
    height: calc(
      100% - clamp(110px, calc(6.875rem + ((1vw - 19.2px) * -0.9375)), 116px)
    );
    top: clamp(110px, calc(6.875rem + ((1vw - 19.2px) * -0.9375)), 116px);
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    display: block;
    width: 100%;
    height: clamp(111px, calc(6.9375rem + ((1vw - 19.2px) * -0.7812)), 116px);
    background-color: var(--light);
  }
}

.title {
  display: flex;
  flex-direction: column;
  position: inherit;
  text-align: center;
  font-size: clamp(96px, calc(6.875rem + ((1vw - 19.2px) * 2.1875)), 110px);
  font-weight: 300;
  line-height: 1;
  text-transform: uppercase;
  z-index: 2;
  color: var(--light);
  letter-spacing: -1.2px;
  text-transform: uppercase;
  margin-bottom: clamp(0px, calc(0rem + ((1vw - 19.2px) * -1.4062)), 9px);
  em {
    color: var(--night);
    font-style: normal;
  }
}

.creating {
  position: inherit;
  max-width: 553px;
  width: 100%;

  color: var(--light);
  text-align: center;
  font-size: clamp(24px, calc(1.625rem + ((1vw - 19.2px) * 0.3125)), 26px);
  font-weight: 300;
  line-height: clamp(28px, calc(1.875rem + ((1vw - 19.2px) * 0.3125)), 30px);
  letter-spacing: 0;
  margin: 0 auto 38px auto;
  z-index: 5;
}

@media (max-width: 1280px) {
  .root {
    background-image: url("../../../assets/img/mainHero/hero1280.jpg");

    min-height: unset;
    height: clamp(471px, calc(39.6875rem + ((1vw - 12.8px) * 17.8261)), 635px);
  }

  //тень
  .root::before {
    top: clamp(113px, calc(7.25rem + ((1vw - 12.8px) * 1.1719)), 116px);
    height: calc(
      100% - clamp(113px, calc(7.25rem + ((1vw - 12.8px) * 1.1719)), 116px)
    );
  }

  .title {
    font-size: clamp(44px, calc(6rem + ((1vw - 12.8px) * 5.6522)), 96px);
    line-height: clamp(36px, calc(6rem + ((1vw - 12.8px) * 6.5217)), 96px);
    margin: 0 auto;
  }

  .creating {
    font-size: clamp(12px, calc(1.5rem + ((1vw - 12.8px) * 1.3043)), 24px);
    line-height: clamp(15.6px, calc(1.75rem + ((1vw - 12.8px) * 1.3478)), 28px);
    max-width: clamp(210px, calc(34.6875rem + ((1vw - 12.8px) * 37.5)), 555px);
  }
}

@media (max-width: 1024px) {
  .root {
    padding-top: clamp(0px, calc(0rem + ((1vw - 10.24px) * -5.8735)), 39px);
  }

  .root::after {
    height: clamp(77px, calc(5.125rem + ((1vw - 10.24px) * 0.753)), 82px);
  }

  //тень
  .root::before {
    top: clamp(76px, calc(5.0625rem + ((1vw - 10.24px) * 0.7541)), 81px);
    height: calc(
      100% - clamp(76px, calc(5.0625rem + ((1vw - 10.24px) * 0.7541)), 81px)
    );
  }

  .title {
    font-weight: 300;
  }
}

@media (max-width: 360px) {
  .root {
    background-image: url("../../../assets/img/mainHero/hero360.jpg");
    background-size: auto;
  }

  .root::before {
    top: 112px;
    height: calc(100% - 112px);
  }

  .root::after {
    height: 112px;
  }

  .title {
    em {
      max-width: 300px;
      margin: 0 auto;
    }
  }
}
