.line {
  display: grid;
  grid-template-columns: 3fr 4fr 1fr;
  gap: 0;
  color: var(--grey2);
  align-items: center;
  border-top: 1px solid var(--grey2);
}

.tip {
  // font-family: "Cormorant Infant";
  font-size: 40px;
  line-height: 62px;
  padding-top: 23px;
  padding-bottom: 23px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  max-width: clamp(360px, calc(24.375rem + ((1vw - 19.2px) * 4.6875)), 390px);

  width: 100%;
}

.show1 {
  display: flex;
}

.show2 {
  display: none;
}

.line button {
  margin-left: auto;
}

.request {
  position: relative;
  height: fit-content;
  border: none;
  background: transparent;
  color: var(--grey2);
  font-size: 12px;
  line-height: clamp(
    15.6px,
    calc(1.1625rem + ((1vw - 19.2px) * 0.4688)),
    18.6px
  );
  font-weight: 400;
  padding-bottom: 10px;
  margin-right: 15px;
  margin-left: auto;
  transition: width 0.7s;
  text-transform: uppercase;
}

.request::after {
  content: "";
  position: absolute;
  bottom: 1px;
  display: block;
  width: calc(100% + 20px);
  height: 1px;
  background-color: var(--grey2);
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.4s;
}

.request:hover::after {
  width: 137%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1280px) {
  .tip {
    font-size: clamp(24px, calc(2.5rem + ((1vw - 12.8px) * 1.7391)), 40px);
    line-height: clamp(24px, calc(3.875rem + ((1vw - 12.8px) * 4.1304)), 62px);
    min-width: clamp(
      255px,
      calc(18.125rem + ((1vw - 12.8px) * 13.6719)),
      290px
    );
  }

  .description {
    font-size: clamp(12px, calc(1rem + ((1vw - 12.8px) * 0.4348)), 16px);
    line-height: clamp(
      15.6px,
      calc(1.5625rem + ((1vw - 12.8px) * 1.0217)),
      25px
    );
    max-width: clamp(
      215px,
      calc(22.1875rem + ((1vw - 12.8px) * 54.6875)),
      355px
    );
  }

  .request {
  }

  .request::after {
    bottom: 2px;
  }
}

@media (max-width: 1024px) {
  .container {
    margin-bottom: 30px;
  }

  .line {
    grid-template-columns: 7fr 1fr;
  }
  .show1 {
    display: none;
  }
  .show2 {
    display: flex;
  }

  .tip {
    min-width: unset;
  }

  .description {
    font-weight: 300;
    max-width: 100%;
    padding: 0;
    margin: 0;
  }

  .request {
    font-weight: 300;
    margin-right: 10px;
  }
}

@media (max-width: 360px) {
  .tip {
    padding-top: 25px;
    padding-bottom: 14px;
  }
  .show2 {
    margin-bottom: -5px;
  }
  .request {
    margin-top: 20px;
  }
}
