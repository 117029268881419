.description {
  display: flex;
  flex-direction: column;
  font-size: clamp(14px, calc(1rem + ((1vw - 19.2px) * 0.3125)), 16px);
  font-weight: 400;
  gap: clamp(19px, calc(1.5rem + ((1vw - 19.2px) * 0.7813)), 24px);
  max-width: clamp(270px, calc(22.5rem + ((1vw - 19.2px) * 14.0625)), 360px);
  width: 100%;
  color: var(--grey2);
  padding-left: clamp(39px, calc(3.5625rem + ((1vw - 19.2px) * 2.8125)), 57px);
  line-height: clamp(16.8px, calc(1.5625rem + ((1vw - 19.2px) * 1.2812)), 25px);
}

.list {
  display: flex;
  flex-direction: column;
  gap: clamp(0px, calc(0rem + ((1vw - 19.2px) * -1.875)), 12px);
  margin-left: 20px;
}

.item {
  list-style-type: disc;
}
.more {
  position: relative;
  margin: auto 20px 0px auto;
  padding-bottom: 10px;
  color: var(--grey2);
  width: max-content;
  font-size: 12px;
}

.more::after {
  content: "";
  position: absolute;
  display: block;
  width: 115%;
  height: 1px;
  background-color: var(--grey2);
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.4s;
}
.more:hover::after {
  width: 130%;
}

.show2 {
  display: none;
}

@media (max-width: 1280px) {
  .description {
    font-size: clamp(12px, calc(0.875rem + ((1vw - 12.8px) * 0.2174)), 14px);
    font-weight: 400;
    line-height: clamp(
      14.4px,
      calc(1.05rem + ((1vw - 12.8px) * 0.2609)),
      16.8px
    );
  }
}

@media (max-width: 1024px) {
  .description {
    max-width: 100%;
    font-weight: 300;
    padding-left: 0;
    gap: clamp(20px, calc(1.25rem + ((1vw - 10.24px) * -0.3012)), 22px);
  }

  .list {
    gap: 2px;
  }

  .more {
    margin-top: 48px;
    margin-left: 10px;
    font-weight: 300;
    font-size: clamp(10px, calc(0.75rem + ((1vw - 10.24px) * 0.3012)), 12px);
  }

  .show1 {
    display: none;
  }
  .show2 {
    display: inline;
  }
}
